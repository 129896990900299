$.fn.queryBuilder.define('MDToolsPlugin', function(options) {
  this.on('getRuleInput.filter', function(h, rule, name) {
    var filter = rule.filter;
    if ((filter.type === 'date')) {
      h.value = h.value.replace('type="text"', 'type="date"' )
    }
    if ((filter.type === 'datetime')) {
      h.value = h.value.replace('type="text"', 'type="datetime-local"' )
    }
    if ((filter.type === 'time')) {
      h.value = h.value.replace('type="text"', 'type="time"' )
    }
  })

  this.on('afterUpdateRuleOperator.queryBuilder', function(e, rule) {
    // after [contains_near] is selected, the second Input should change to select box ?

    if (rule.operator.type === "until_rundate" || rule.operator.type === "from_rundate" || 
        rule.operator.type === 'less_until_rundate' || rule.operator.type === 'greater_from_rundate') {
      $("#"+ rule.id + " div.rule-value-container").first().children('input').each(function(e) {
        if($(this).attr("name").endsWith("0")) {
          $(this).attr("pattern","[dDmM]{1}||[wwWWyyYY]{2}");
          $(this).attr("type","text");
          $(this).attr("placeholder","Enter D, WW, M or YY");
          $(this).attr("title","Enter D(Day), WW(Week), M(Month) or YY(Year)");
          $(this).attr("maxlength","2");
          maxlength = "30"          
        } else if ($(this).attr("name").endsWith("1")) {
          $(this).attr("type","number");
          $(this).attr("placeholder","Amount");
        } else {
          console.log("not in any");
        }
      });

    }
  })
}, {})
